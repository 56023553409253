<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <el-form-item style="margin-top: auto;margin-left: 0;margin-right: 0;">
                    <div class="fill-btn" @click="showAdd=true">新增</div>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text"></div>
            </div>
            <el-table :data="menuList" stripe
                      v-loading="loading"
                      row-key="id">
                <el-table-column prop="name" label="名称" min-width="120px"></el-table-column>
                <el-table-column prop="path" label="页面路径" min-width="120px"></el-table-column>
                <el-table-column prop="created_at" min-width="160px" label="创建时间"></el-table-column>
                <el-table-column width="200">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <i class="iconfont icon-shanchu cur-p" @click="permissionDel(row)"></i>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <HgDialog title="编辑账号" :width="500" :maskClose="false" v-model="showAdd">
            <el-form class="dialog-form-content" :model="form" :rules="getRules" ref="editForm" label-width="120px">
                <el-form-item label="父级选择">
                    <el-cascader
                            :options="menuList"
                            @change="handParentChange"
                            :props="{
                                checkStrictly: true,
                                label: 'name',
                                children: 'children',
                                value: 'id'
                            }"
                            clearable></el-cascader>
                </el-form-item>
                <el-form-item label="页面选择">
                    <el-cascader
                            :options="options"
                            ref="pages"
                            @change="handPageChange"
                            :props="{
                                checkStrictly: true,
                                label: 'title',
                                children: 'children',
                                value: 'path'
                            }"
                            clearable></el-cascader>
                </el-form-item>
                <el-form-item label="是否菜单">
                    <el-switch
                            v-model="form.is_menu"
                            active-text="是"
                            :active-value="1"
                            inactive-text="否"
                            :inactive-value="2">
                    </el-switch>
                </el-form-item>
                <el-form-item>
                    <div class="fill-btn" v-load="editing" @click="permissionAdd">保存</div>
                </el-form-item>
            </el-form>
        </HgDialog>
    </div>
</template>

<script>
    import {tempRouterMoudleList} from './../../router/index'
    import {delToast} from './../../utlis/decorators'
    export default {
        name: "authManage",
        data(){
            return{
                loading: false,
                showAdd: false,
                editing: false,
                options: tempRouterMoudleList,
                tableData: [],
                form:{
                    is_menu: 1, //1是，2不是
                    name: '', // 名字
                    p_id: '',
                    path: ''
                },
				menuList:[],
            }
        },
        created() {
            // this.getPermissionList();
            this.menuListAll();
        },
        computed:{
            getRules(){
                return []
            }
        },
        methods:{
            handParentChange(e){
                this.form.p_id = e[e.length - 1]
            },
            handPageChange(e){
                this.form.path = e[e.length - 1];
                this.form.name = this.$refs.pages.getCheckedNodes()[0].label;
            },
            getName(array, id){

            },
            async getPermissionList(){
                this.loading = true;
                let [e, data] = await this.$api.permissionListApi({
					limit:1000
				});
                this.loading = false;
                if(e) return;
                if(data.code === 200){
                    this.tableData = data.data.data;
                }
            },
			async menuListAll(){
				this.loading = true;
                let [e, data] = await this.$api.menuListAll();
				this.loading = false;
                if(e) return;
                if(data.code === 200){
                    this.menuList = data.data;
                }
            },
            @delToast()
            async permissionDel({id}){
                let [e, data] = await this.$api.permissionDelApi({id});
                if(e) return;
                if(data.code === 200){
                    this.menuListAll();
                    this.$message.success('删除成功');
                }else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            async permissionAdd(){
                let [e, data] = await this.$api.permissionAddApi(this.form);
                if(e) return;
                if(data.code === 200){
                    this.menuListAll();
                    this.showAdd = false;
                }else {
                    this.$message.warning(data.msg)
                }
            }
        }
    }
</script>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }
</style>
